import * as React from "react";

export default function Navigation() {
  return (
    <div className="header-main">
      <div className="header-container container">
        <div className="header-wrap">
          <div
            className="header-logo logo animated"
            data-animate="fadeInDown"
            data-delay=".65"
          >
            <a href="./" className="logo-link">
              <img
                width={200}
                height={40}
                className="logo-dark"
                src="assets/images/logo.svg"
                alt="logo"
              />
              <img
                width={200}
                height={40}
                className="logo-light"
                src="assets/images/logo.svg"
                alt="logo"
              />
            </a>
          </div>

          <div className="header-nav-toggle">
            <a
              href="#"
              className="navbar-toggle"
              data-menu-toggle="header-menu"
            >
              <div className="toggle-line">
                <span></span>
              </div>
            </a>
          </div>

          <div className="header-navbar header-navbar-s1">
            <nav className="header-menu" id="header-menu">
              <ul
                className="menu animated remove-animation"
                data-animate="fadeInDown"
                data-delay=".75"
              >
                <li className="menu-item">
                  <a className="menu-link nav-link" href="/#usp">
                    USP
                  </a>
                </li>
                <li className="menu-item">
                  <a className="menu-link nav-link" href="/#platform">
                    Platform
                  </a>
                </li>
                <li className="menu-item">
                  <a className="menu-link nav-link" href="/#token">
                    Token
                  </a>
                </li>
                <li className="menu-item">
                  <a className="menu-link nav-link" href="/#roadmap">
                    Roadmap
                  </a>
                </li>
                <li className="menu-item">
                  <a className="menu-link nav-link" href="/#faqs">
                    FAQ
                  </a>
                </li>
                <li className="menu-item">
                  <a className="menu-link nav-link" href="/#team">
                    Team
                  </a>
                </li>
                <li className="menu-item">
                  <a className="menu-link nav-link" href="/#contact">
                    Get in Touch
                  </a>
                </li>
              </ul>
              <ul
                className="menu-btns menu-btns-s2 align-items-center animated remove-animation"
                data-animate="fadeInDown"
                data-delay=".85"
              >
                <li>
                  <a
                    href="https://firebasestorage.googleapis.com/v0/b/changex-io.appspot.com/o/changex-whitepaper-v4.pdf?alt=media&token=85d7033d-ce62-4fd2-aefd-b50f2a758530"
                    className="btn btn-md btn-outline btn-grad on-bg-theme-dark-alt"
                  >
                    <span className="text-white">White Paper</span>
                  </a>
                </li>
                <li>
                  <a
                    href="https://token.changex.io/"
                    className="menu-link nav-link btn btn-md btn-grad btn-grad-alt no-change"
                  >
                    <span>ICO investor portal</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}
