import * as React from "react";

import { Helmet } from "react-helmet";
import Footer from "../components/Footer";

import Navigation from "../components/Nav";

// markup
const IndexPage = () => {
  return (
    <>
      <div className="nk-wrap">
        <Helmet
          bodyAttributes={{
            class: "nk-body body-wider theme-dark mode-onepage",
          }}
        >
          <meta charset="utf-8" />
          <meta name="author" content="ChangeX" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
          />
          <meta name="description" content="" />
          <link rel="shortcut icon" href="/assets/images/icon.png" />
          <title> Terms of use | ChangeX</title>

          <link rel="stylesheet" href="assets/css/vendor.bundle.css?ver=195" />
          <link rel="stylesheet" href="assets/css/style-dark.css" />
          <link rel="stylesheet" href="assets/css/theme.css?ver=195" />
        </Helmet>
        <header
          className="nk-header page-header is-transparent is-sticky is-shrink is-dark"
          id="header"
        >
          <Navigation />
        </header>
        <main className="nk-pages">
          <section className="section bg-theme-alt ov-h pb-0 tc-light mt-50">
            <div className="container">
              <div className="section-head section-head-s4 wide-auto-sm text-center">
                <h5
                  className="title title-s2 title-s2-alt animated"
                  data-animate="fadeInUp"
                  data-delay=".1"
                >
                  Terms of use
                </h5>
              </div>
              <article class="text-content wrap mb-5">
                <h5 id="1-introduction">1. INTRODUCTION</h5>

                <p>
                  These terms of use are entered into by and between you and
                  Olympus Capital JSC (“ChangeX”, “we” or “us”). The following
                  Terms of Use, contained herein on this Website, shall govern
                  your use of this Website, including all pages within this
                  Website (collectively referred to herein below as this
                  “Website”).
                </p>

                <p>
                  These Terms of Use (this “Agreement”) is a binding contract
                  between you, an individual user (“User” or “you”), and Olympus
                  Capital JSC (“ChangeX,” “we,” “us,” or “our”) governing your
                  use of www.changex.io (“service”). This website is operated by
                  Olympus Capital JSC with registration number XXXXXX and
                  registered address bul. Simeonovsko shose 33, Sofia 1700,
                  Bulgaria.
                </p>

                <p>
                  BY ACCESSING OR USING THE WEBSITE, YOU AGREE THAT YOU HAVE
                  READ, UNDERSTOOD, AND AGREE TO BE BOUND BY THIS AGREEMENT. IF
                  YOU DO NOT AGREE TO THE TERMS OF THIS AGREEMENT, THEN YOU MAY
                  NOT USE THE WEBSITE. If you are accessing the Website on
                  behalf of a business or corporate entity (“Organization”),
                  then you hereby represent and warrant that you have the
                  authority to bind that Organization and your acceptance of
                  this Agreement will be treated as acceptance by the
                  Organization. In that event, “User,” “you” and “your” in this
                  Agreement will refer to the Organization.
                </p>

                <h5 id="2-eligibility">2. ELIGIBILITY</h5>

                <p>
                  This Website is not for use by any minors (defined as those
                  who are not at least 18 years of age), and you must not use
                  this Website if you are a minor. By using this Website, you
                  represent and warrant that you:
                </p>

                <p>
                  (i) are 18 years of age or older (ii) are not barred to use
                  the Website under any applicable law (iii) are using the
                  Website only for your own personal use.
                </p>

                <h5 id="3-intellectual-property">3. INTELLECTUAL PROPERTY</h5>

                <p>
                  All content on this Website is owned or licensed by ChangeX
                  and is protected by copyright, patent, trademark and any other
                  applicable laws, unless otherwise specified hereby.
                </p>

                <p>
                  The trademarks, trade names, service marks and logos of
                  ChangeX and others used on the Website (hereinafter the
                  “Trademarks”) are the property of ChangeX. The software,
                  applications, text, images, graphics, data, prices, trades,
                  charts, graphs, video and audio materials used on this Website
                  belong to ChangeX.
                </p>

                <p>
                  It is prohibited to copy, reproduce, modify, republish,
                  upload, post, transmit, scrape, collect or distribute the
                  Trademarks or any other content on the Website no matter
                  manual or automated, unless it is otherwise intended by
                  ChangeX, its Website and/or System. The use of any content
                  from the Website on any other site or a networked computer
                  environment for any other purpose is strictly prohibited,
                  unless it is otherwise intended by ChangeX, its Website and/or
                  System; any such unauthorized use may violate copyright,
                  patent, trademark and any other applicable laws and could
                  result in criminal or civil penalties.
                </p>

                <h5 id="4-restrictions">4. RESTRICTIONS</h5>

                <p>
                  4.1 ChangeX reserves the right to suspend or terminate any
                  User Account, to report to relevant law enforcement
                  authorities if we reasonably believe to be required to do so
                  by law or in order to comply with recommendations issued by a
                  relevant government authority or recognized body for the
                  prevention of crime.
                </p>

                <p>
                  4.2 The information provided on this Website is not intended
                  for distribution to, or use by, any person or entity in any
                  jurisdiction or country where such distribution or use would
                  be contrary to law or regulation or which would subject
                  ChangeX or its affiliates to any registration requirement
                  within such jurisdiction or country.
                </p>

                <p>
                  4.3 Each product and service referred to on this Website is
                  not intended to be made available to U.S. persons. This
                  Website will not be considered a solicitation for or offering
                  of any investment product or service to any person in any
                  jurisdiction where such solicitation or offering would be
                  illegal.
                </p>

                <p>
                  You are expressly and emphatically restricted from all of the
                  following:
                </p>

                <ul>
                  <li>
                    Selling, sublicensing and/or otherwise commercializing any
                    Website material.
                  </li>
                  <li>
                    Using this Website in any way that is, or may be, damaging
                    to this Website.
                  </li>
                  <li>
                    Using this Website in any way that impacts user access to
                    this Website.
                  </li>
                  <li>
                    Using this Website contrary to applicable laws and
                    regulations, or in a way that causes;
                  </li>
                  <li>
                    May cause harm to the Website, or to any person or business
                    entity.
                  </li>
                  <li>
                    Engaging in any data mining, data harvesting, data
                    extracting or any other similar activity in relation to this
                    Website, or while using this Website.
                  </li>
                  <li>
                    For the purpose of exploiting, harming or attempting to
                    exploit or harm minors in any way by exposing them to
                    inappropriate content, asking for personally identifiable
                    information or otherwise.
                  </li>
                  <li>
                    To send, knowingly receive, upload, download, use or re-use
                    any material which does not comply with these Terms of Use.
                  </li>
                  <li>
                    To transmit, or procure the sending of, any advertising or
                    promotional material without our prior written consent,
                    including any “junk mail”, “chain letter” or “spam” or any
                    other similar solicitation.
                  </li>
                  <li>
                    To impersonate or attempt to impersonate ChangeX, another
                    user or any other person or entity (including, without
                    limitation, by using e-mail addresses or screen names
                    associated with any of the foregoing).
                  </li>
                  <li>
                    To engage in any other conduct that restricts or inhibits
                    anyone’s use or enjoyment of the Website, or which, as
                    determined by us, may harm ChangeX or users of the Website
                    or expose them to liability.
                  </li>
                  <li>
                    Use the Websites in any manner that could disable,
                    overburden, damage, or impair the Website or interfere with
                    any other party’s use of the Website, including their
                    ability to engage in real time activities through the
                    Websites.
                  </li>
                  <li>
                    Use any robot, spider or other automatic device, process or
                    means to access the Website for any purpose, including
                    monitoring or copying any of the material on the Websites.
                  </li>
                  <li>
                    Use any manual process to monitor or copy any of the
                    material on the Websites or for any other unauthorized
                    purpose without our prior written consent.
                  </li>
                  <li>
                    Use any device, software or routine that interferes with the
                    proper working of the Websites.
                  </li>
                  <li>
                    Introduce any viruses, trojan horses, worms, logic bombs or
                    other material which is malicious or technologically
                    harmful.
                  </li>
                  <li>
                    Attempt to gain unauthorized access to, interfere with,
                    damage or disrupt any parts of the Website, the server on
                    which the Websites is stored, or any server, computer or
                    database connected to the Website.
                  </li>
                  <li>
                    Attack the Websites via a denial-of-service attack or a
                    distributed denial-of-service attack.
                  </li>

                  <li>
                    <p>
                      Otherwise attempt to interfere with the proper working of
                      the Website. ​​
                    </p>

                    <h5 id="5-your-content">5. YOUR CONTENT</h5>
                  </li>
                </ul>

                <p>
                  In these Terms of Use, “Your Content” shall mean any audio,
                  video, text, images or other material you choose to display on
                  this Website. With respect to Your Content, by displaying it,
                  you grant ChangeX a non-exclusive, worldwide, irrevocable,
                  royalty-free, sublicensable license to use, reproduce, adapt,
                  publish, translate and distribute it in any and all media.
                </p>

                <p>
                  Your Content must be your own and must not be infringing on
                  any third party’s rights. ChangeX reserves the right to remove
                  any of Your Content from this Website at any time, and for any
                  reason, without notice.
                </p>

                <h5 id="6-e-mail-and-marketing">6. E-MAIL AND MARKETING</h5>

                <p>
                  Users who complete the registration process on the Website are
                  at the same time granting ChangeX permission to send them
                  e-mail messages for marketing and general communication
                  purposes at the e-mail address they have provided. This
                  service may be discontinued at any time by unsubscribing, from
                  the links at the bottom of the latest received e-mail.
                </p>

                <h5 id="7-password-security-and-notification">
                  7. PASSWORD SECURITY AND NOTIFICATION
                </h5>

                <p>
                  Certain parts of the Website are protected by passwords or
                  require a login and are restricted to authorized users only.
                  You may not obtain unauthorized access to such parts of the
                  Website, or to any other protected materials or information,
                  through any means not intentionally made available by ChangeX
                  for your specific use.
                </p>

                <h5 id="8-no-warranties">8. NO WARRANTIES</h5>

                <p>
                  This Website is provided “as is,” with all faults, and ChangeX
                  makes no express or implied representations or warranties, of
                  any kind related to this Website or the materials contained on
                  this Website. Additionally, nothing contained on this Website
                  shall be construed as providing consultation or advice to you.
                  Any reliance you place on such information is strictly at your
                  own risk. We disclaim all liability and responsibility arising
                  from any reliance placed on such materials by you or any other
                  visitor to the Website, or by anyone who may be informed of
                  any of its contents.
                </p>

                <h5 id="9-limitations-of-liability">
                  9. LIMITATIONS OF LIABILITY
                </h5>

                <p>
                  IN NO EVENT SHALL CHANGEX, ITS AFFILIATES OR THEIR LICENSORS,
                  SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS OR DIRECTORS BE
                  LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY,
                  ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO
                  USE, THE WEBSITE, ANY WEBSITES LINKED TO IT, ANY CONTENT ON
                  THE WEBSITES OR SUCH OTHER WEBSITES OR ANY SERVICES OR ITEMS
                  OBTAINED THROUGH THE WEBSITES OR SUCH OTHER WEBSITES,
                  INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL,
                  CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED
                  TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS,
                  LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR
                  ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF
                  DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE),
                  BREACH OF CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE. THE
                  FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE
                  EXCLUDED OR LIMITED UNDER APPLICABLE LAW WHICH MAY INCLUDE
                  FRAUD.
                </p>

                <h5 id="10-indemnification">10. INDEMNIFICATION</h5>

                <p>
                  You agree to defend, indemnify and hold harmless ChangeX, its
                  affiliates, licensors and service providers and their
                  respective officers, directors, employees, contractors,
                  agents, licensors, suppliers, successors and assigns from and
                  against any claims, liabilities, damages, judgments, awards,
                  losses, costs, expenses or fees (including reasonable
                  attorneys’ fees) arising out of or relating to your violation
                  of these Terms of Use or your use of the Website, including,
                  but not limited to, any use of the Website’s content, services
                  and products other than as expressly authorized in these Terms
                  of Use or your use of any information obtained from the
                  Website.
                </p>

                <h5 id="11-severability">11. SEVERABILITY</h5>

                <p>
                  If any provision of these Terms of Use is found to be
                  unenforceable or invalid under any applicable law, such
                  unenforceability or invalidity shall not render these Terms
                  unenforceable or invalid as a whole, and such provisions shall
                  be deleted without affecting the remaining provisions herein.
                </p>

                <h5 id="12-amendments-to-the-terms-of-use">
                  12. AMENDMENTS TO THE TERMS OF USE
                </h5>

                <p>
                  ChangeX reserves the right to amend, add, or remove parts of
                  these Terms of Use at any time and at its sole discretion.
                  Your continued use of the Website signifies that you accept
                  and agree to the changes, and that all subsequent transactions
                  by you will be subject to the amended Terms of Use.
                </p>

                <p>
                  If you have any questions with respect to these Terms of Use,
                  your rights and obligations arising from these Terms of Use
                  and/or your use of the Website, your Account or any other
                  matter, please contact compliance@changex.io. We will get back
                  to you in a timely fashion.
                </p>

                <h5 id="13-entire-agreement">13. ENTIRE AGREEMENT</h5>

                <p>
                  These Terms, including any legal notices and disclaimers
                  contained on this Website, constitute the entire agreement
                  between ChangeX and you in relation to your use of this
                  Website, and supersede all prior agreements and understandings
                  with respect to the same.
                </p>

                <h5 id="14-governing-law-and-jurisdiction">
                  14. GOVERNING LAW AND JURISDICTION
                </h5>

                <p>
                  These Terms of Use shall be governed by and construed in
                  accordance with the laws of the Republic of Bulgaria. You
                  agree that the District Court of Sofia shall be used as first
                  instance for any cause of action arising out of or relating to
                  Service or these Terms.
                </p>
              </article>
            </div>
          </section>
          <Footer />
        </main>
      </div>
      <div className="preloader">
        <span className="spinner spinner-round"></span>
      </div>
    </>
  );
};

export default IndexPage;
