import * as React from "react";

export default function Footer() {
  return (
    <footer className="nk-footer bg-theme-dark">
      <div className="tc-light">
        <div className="container">
          <div className="nk-block">
            <div className="bg-theme-accent-alt round subscribe-wrap">
              <div className="row text-center text-md-left justify-content-center align-items-center gutter-vr-25px">
                <div className="col-lg-6">
                  <div className="wide-auto-sm">
                    <h4 className="title-sm">Complete your orders now</h4>
                    <p>
                      Log in to the platform and complete your orders for CHANGE before June 30, 00:00 EEST.
                    </p>
                  </div>
                </div>

                <div className="col-lg-6 text-right">
                  <a
                    href="https://token.changex.io"
                    className="btn btn-md btn-grad border border-white"
                  >
                    Participate
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="nk-ovm ovm-top ovm-h-50 bg-theme-alt"></div>
      </div>
      <div class="nk-footer bg-theme-alt">
        <section class="section section-footer section-m bg-transparent tc-light">
          <div class="container">
            <div class="nk-block block-footer">
              <div class="row justify-content-between gutter-vr-30px">
                <div class="col-lg-6 col-sm-6">
                  <div class="wgs wgs-menu">
                    <a href="#" class="wgs-logo">
                      <img
                        width={300}
                        height={66.46}
                        src="assets/images/logo.svg"
                        alt="logo"
                      />
                    </a>
                    <ul class="social">
                      <li>
                        <a href="https://www.facebook.com/ChangeXapp">
                          <em class="social-icon fab fa-facebook-f"></em>
                        </a>
                      </li>
                      <li>
                        <a href="https://twitter.com/ChangeXapp">
                          <em class="social-icon fab fa-twitter"></em>
                        </a>
                      </li>
                      <li>
                        <a href="https://t.me/changex_official">
                          <em class="social-icon fab fa-telegram"></em>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="col-lg-6">
                  <div class="wgs wgs-menu">
                    <div class="wgs-body ml-lg-n3">
                      <ul class="wgs-links wgs-links-3clumn">
                        <li>
                          <a href="/#usp">USP</a>
                        </li>
                        <li>
                          <a href="/#platform">Platform</a>
                        </li>
                        <li>
                          <a href="/#roadmap">Roadmap</a>
                        </li>
                        <li>
                          <a href="/#ico">ICO</a>
                        </li>
                        <li>
                          <a href="https://firebasestorage.googleapis.com/v0/b/changex-io.appspot.com/o/changex-whitepaper-v4.pdf?alt=media&token=85d7033d-ce62-4fd2-aefd-b50f2a758530">
                            White Paper
                          </a>
                        </li>
                        <li>
                          <a href="/#faq">FAQ</a>
                        </li>

                        <li>
                          <a href="/#team">Team</a>
                        </li>
                        <li>
                          <a href="/#contact">Get in Touch</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="footer-bottom pdt-r">
                <div class="row justify-content-center">
                  <div class="col-lg-7">
                    <div class="copyright-text copyright-text-s3">
                      <p>
                        <span class="d-block">
                          Copyright &copy; 2022, ChangeX Ltd.{" "}
                        </span>
                        All trademarks and copyrights belong to their respective
                        owners.
                      </p>
                    </div>
                  </div>
                  <div class="col-lg-5 text-lg-right">
                    <ul class="footer-links">
                      <li>
                        <a href="https://token.changex.io/privacy-policy">
                          Privacy Policy
                        </a>
                      </li>
                      <li>
                        <a href="https://token.changex.io/terms-and-condition">
                          Terms &amp; Conditions
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </footer>
  );
}
